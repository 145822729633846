import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';

async function fetchData() {
	return axios.get('/userStore').then(x => x?.data?.map(d => ({...d, data: JSON.parse(d.data)})) ?? []);
}

async function postData(data) {
	if(typeof data.data !== 'string')
		data.data = JSON.stringify(data.data);
	data.id ??= 0;
	data.user_id ??= 0;
	return axios.post('/userStore', data).then(x => x?.data ?? []);
}

const queryKey = ['userStore'];
export function useUserStore(category, key, initial) {
	if (category === undefined || key === undefined || initial === undefined)
		throw new Error('Argument is required and undefined in UseUserStore');
	let { data, isLoading: query_is_loading, error: query_error, refetch } = useQuery({
		queryKey: queryKey,
		queryFn: () => fetchData(category, key).then(d => {
			return d?.filter(x => x.key === key && x.category === category) ?? [];
		}),
		staleTime: 60 * 1000,
	});
	let mutation = useMutation({
		mutationFn: postData,
		onSuccess: () => refetch(),
		onError: console.error,
		retry: 2,
	});
	const set = (changed) => {
		const id = data.find(x => x.key === key && x.category === category)?.id ?? 0;
		const c = { id, key, category, data: changed};
		mutation.mutate(c);
	}
	return {
		data: data?.find(x => x.key === key && x.category === category)?.data ?? initial,
		mutate: set,
		isError: query_error === undefined || mutation.isError,
		error: query_error ?? mutation.error,
		isLoading: query_is_loading || mutation.isPending,
	};
}
