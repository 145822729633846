import '../styles/NavUser.sass';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../services/UserContext';

export default function NavUser () {
  let user = useContext(UserContext);
	const { logout } = useAuth0();
	if(user === {})
		return <></>;
	return (
		<div className="profile-nav">
			<img src={user.picture} alt="Profile" className="round" />
			<div className='button_group'>
				<button className="non-primary" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>logout</button>
				<button className="non-primary"><Link to="/profile">profile</Link></button>
			</div>
		</div>
	);
}
