import './styles/App.sass';
import React, { useEffect, useMemo, useState } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	NavLink,
} from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query'
import Spells from './Pages/Spells';
import SpellDetails from './Pages/SpellDetails';
import Monsters from './Pages/Monsters';
import CharacterPage from './Pages/Character';
import DicePage from './Pages/Dice';
import MonsterStats from './Pages/MonsterStats';
import HomePage from './Pages/Home';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from 'axios';
import NavUser from './components/NavUser';
import ProfilePage from './Pages/Profile';
import UserManagement from './Pages/UserManagement';
import { UserContext } from './services/UserContext';

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.timeout = 10000; // 10 sec for timeout.

function App() {
	const { user, isAuthenticated, isLoading: auth0Loading, loginWithRedirect, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
	const [ token, set_token ] = useState(false);
	const ProtectedRoute = ({ component, ...propsForComponent}) => {
		const Cp = withAuthenticationRequired(component);
		return <Cp {...propsForComponent} />
	}
	const { data: user_list, isLoading:usersLoading, refetch: userRefetch } = useQuery({
		queryKey: ['users'],
		queryFn: async () => { 
      if(!isAuthenticated)
        return null;
      else
        return await axios.get('/user').then(x => x?.data);
    },
		staleTime: 60 * 1000 * 10, // 10 minutes
	});
	const userMutation = useMutation({mutationFn: () => axios.post('/user'), gcTimer: Infinity, mutationKey: ['users']});
  const [ hasMutated, setHasMutated ] = useState(false);

  let myUserData = useMemo(() => {
    if(!user)
      return {};
    return {
      ...(user['dnd.tvonsegg.com/user'] ?? {}),
      ...(user['dnd.tvonsegg.com/app'] ?? {}),
      picture: user.picture,
    };
  }, [user]);

	useEffect(() => {
		async function getToken() {
			if(auth0Loading || !isAuthenticated)
        return;
      let accessToken = null;
      try {
        accessToken = await getAccessTokenSilently({
          audience: new URL(process.env.REACT_APP_API).host,
          cacheMode: 'on',
        });
      }
      catch {
        accessToken = await getAccessTokenWithPopup({
          audience: new URL(process.env.REACT_APP_API).host,
          cacheMode: 'on',
        });
      }
      if(accessToken == null || accessToken === token)
        return;
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      userRefetch();
      set_token(accessToken);
		}
    getToken();
	});

  // make sure that the user is set for the api.
	useEffect(() => {
    if(!usersLoading && !!token && !hasMutated && user_list !== null && user_list?.find(x => x.id === myUserData.id) === undefined) {
      setHasMutated(true);
      userMutation.mutate({});
    }
  }, [myUserData, usersLoading, hasMutated, token, user_list, setHasMutated, userMutation]);

	if(auth0Loading || usersLoading)
		return <><nav></nav><main>loading...</main></>;
	if(!isAuthenticated || !token) {
		return (
			<>
				<nav>
					<ul role="navigation">
						<li><button onClick={() => loginWithRedirect()}>Log In</button></li>
					</ul>
				</nav>
				<main>
          <h1>Woah not so fast!</h1>
          <p>This is currently an invite only club. If you have an invite, please login - otherwise beat it!</p>
				</main>
			</>
		);
	}

  let isAdmin = (myUserData.roles ?? []).indexOf('admin') !== -1;
	return (
	<>
	  <Router>
      <UserContext.Provider value={myUserData}>
        <nav>
        <NavUser/>
        <ul role="navigation">
          <li><NavLink to="/spell">Spell</NavLink></li>
          <li><NavLink to="/monster">Monster</NavLink></li>
          <li><NavLink to="/monster_stats">Monster Stats</NavLink></li>
          <li><NavLink to="/character">Character</NavLink></li>
          <li><NavLink to="/dice">Dice Util</NavLink></li>
          { isAdmin && <li><NavLink to="/users">Users</NavLink></li>}
        </ul>
        </nav>
        <main>
          <Routes>
            <Route path="/" element={<ProtectedRoute component={HomePage} />} />
            <Route path="/spell" element={<ProtectedRoute component={Spells} />} />
            <Route path="/spell/:id" element={<ProtectedRoute component={SpellDetails} />} />
            <Route path="/monster" element={<ProtectedRoute component={Monsters} />} />
            <Route path="/monster_stats" element={<ProtectedRoute component={MonsterStats} />} />
            <Route path="/character" element={<ProtectedRoute component={CharacterPage} />} />
            <Route path="/dice" element={<ProtectedRoute component={DicePage} />} />
            <Route path="/profile" element={<ProtectedRoute component={ProfilePage} />} />
            <Route path="/users" element={<ProtectedRoute component={UserManagement} />} />
          </Routes>
        </main>
      </UserContext.Provider>
	  </Router>
	</>
	);
}

export default App;
