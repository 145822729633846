import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useModal } from '../UseModal.js';
import { InputElement } from "../components/InputElement.js";

async function fetchUsers() {
	return await axios.get('/user').then(x => x?.data ?? []).catch(e => {console.error(e); return []});
}

function setProp(propName, state, setter) {
  return updated => {
    let next = {...state};
    next[propName] = updated;
    setter(next);
  };
}

function EditModal(user, setUser, close) {
  if(!user)
    return <></>;
  return <>
    <h1>{user.name} : {user.id}</h1>
    <InputElement type='text' label='Name' onChange={setProp('name', user, setUser)} value={user.name} />
    <InputElement type='text' label='Roles' onChange={setProp('roles', user, setUser)} value={user.roles} />
    <div>
      <button className="primary" onMouseDown={() => close({...user})}>Save</button>
    </div>
  </>
}

export default function UserManagement() {
	const { data, isLoading, error, refetch } = useQuery({queryKey: ["users"], queryFn: fetchUsers, staleTime: 10 * 1000});
	let [editModalElement, toggleEdit] = useModal(EditModal, user => {
    if(user.deleteme === true)
      axios.delete(`user/${user.id}`).then(() => {refetch(); console.log('deleted user.');}).catch(console.error);
    else
      axios.put(`user/${user.id}`, user).then(() => refetch()).catch(console.error);
  });
  if(isLoading)
    return <div>Loading...</div>;
  if(!!error)
    return <div>Error: {error}</div>;
  return <>
    {editModalElement}
		<table style={{width: '100%'}}>
			<thead>
				<tr>
					<th scope="col"></th>
					<th scope="col">Name</th>
					<th scope="col">Roles</th>
				</tr>
			</thead>
			<tbody>
				{data?.map(user => 
          <tr key={user.id}>
            <td><button className="primary" onMouseDown={() => toggleEdit({...user})}>edit</button></td>
            <td>{user.name}</td>
            <td>{user.roles}</td>
          </tr>
				)}
			</tbody>
		</table>
  </>;
}
