import { useState } from 'react';
import '../styles/Character.sass';
import axios from 'axios';
import { useQuery } from "@tanstack/react-query";
import { useModal } from '../UseModal.js';
import { Dice } from '../services/Dice.js';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import Tabs from '../Tabs.js';
import CharacterStatsPerLevel from '../components/CharacterStatsPerLevel';
import { useUserStore } from '../UseUserStore.js';

async function fetchData() {
	return axios.get('/monster/analysis').then(x => x?.data ?? []);
}

function clamp(min, med, max) { return Math.max(min, Math.min(med,max)); }
function statToAbilityBonus(stat) { return Math.floor((stat - 10)/2); }

const crHeaderName = 'CR target';
function damageOverLevels(item, character, monsters_stats) {
	let toHitDice = new Dice(!item.toHit ? '1d20' : item.toHit);
	let damageDice = new Dice(item.damageText);
	let line_chart_data = Object.keys(character).map(level => {
		let targetCR = character[level][crHeaderName].value;
		let monsterBins = monsters_stats.find(x => x.cr === targetCR);
		if(!monsterBins)
			return {level: Number(level), expectedDamage: 0};
		Object.keys(character[level]).forEach(k => {
			let value = character[level][k];
			value = value?.value ?? value;
			value = parseFloat(value) ?? value;
			if(['con', 'str', 'dex', 'wis', 'int', 'cha'].indexOf(k) !== -1)
				value = statToAbilityBonus(value)
			toHitDice.variables[k] = value;
		});
		damageDice.variables = toHitDice.variables;
		let hitChance = 0;
		let sumMonsters = monsterBins[item.targetStat].reduce((a, s) => a + s.c, 0);
		let dc = character[level].DC.value;
		if(item.targetStat == 'ac')
			hitChance = monsterBins.ac.reduce((acc, h) => acc + (h.c*clamp(0.05,toHitDice.chance_meet_or_beat(h.v),0.95)), 0) / sumMonsters;
		else
			hitChance = monsterBins[item.targetStat].reduce((acc, h) => acc + (h.c*clamp(0.0, 1-toHitDice.chance_meet_or_beat(dc - statToAbilityBonus(h.v)), 1.0)), 0) / sumMonsters;
		let diceHisto = damageDice.histogram;
		let diceHistoCount = diceHisto?.reduce((acc, c) => acc + c.count, 0) ?? 0;
		let avgDamage = 0;
		if (diceHistoCount > 0) {
			avgDamage = (diceHisto?.reduce((acc, c) => acc + (c.bin * c.count), 0) / diceHistoCount) ?? 0;
		}
		return {level: Number(level), toHit: hitChance, rawDamage: avgDamage, expectedDamage: hitChance * avgDamage}
	});
	line_chart_data.sort((a, b) => a.level - b.level);
	return line_chart_data;
}

function useDamageModalContent(state) {
	const { data: monsters_stats, isLoading, error } = useQuery({queryKey: ["monsters_stats"], queryFn: fetchData, staleTime: 10 * 1000});
	if(state === undefined || isLoading || error) return (<div>no state.</div>);
	let line_chart_data = damageOverLevels(state, state.character, monsters_stats);
	return (
		<>
		<h2>[{state.name}] / [character level]</h2>
		<LineChart height={300} width={600} data={line_chart_data}>
			<XAxis dataKey="level" />
			<YAxis yAxisId="left" width={40}/>
			<YAxis yAxisId="right" orientation='right'  domain={[0, 1]} tickFormatter={(tick) => {
			 return `${(tick*100)}%`;
			 }}/>
			<Tooltip contentStyle={{backgroundColor: 'black'}} />
			<Legend />
			<Line yAxisId="left" type="monotone" dataKey='expectedDamage' dot={false} stroke={'#f0e13a'}/>
			<Line yAxisId="left" type="monotone" dataKey='rawDamage' dot={false} stroke={'#b30e02'}/>
			<Line yAxisId="right" type="monotone" dataKey='toHit' dot={false} stroke={'#4cb302'}/>
		</LineChart>
		</>
	);
}

function useDamageEditModalContent(state, setState, toggle) {
	if(!state)
		return (<span>bad state.</span>)
	return (
		<>
		<h2>{state.name === '' ? "-" : state.name}</h2>
		<label>Name<input type="text" value={state.name} onChange={e => setState({...state, name: e.target.value})}/></label> <label>
			Hit type
			<select value={state.targetStat} onChange={e => {setState({...state, targetStat: e.target.value.toLowerCase()})}}>
				{['ac', 'con', 'str', 'dex', 'wis', 'int', 'cha'].map(s => (				
				<option key={s} value={s}>{s}</option>
				))}
			</select>
		</label>
		{ state.targetStat === 'ac' &&
			<label>To Hit<input type="text" value={state.toHit ?? ''} onChange={e => setState({...state, toHit: e.target.value})}/></label>
		}
		<label>Damage Dice<input type="text" value={state.damageText} onChange={e => setState({...state, damageText: e.target.value})}/></label>
		<div className='button_group'>
			<button className='secondary' onMouseDown={() => {toggle();}}>Save</button>
		</div>
		</>
	);
}

const defaultDamageOptions = [
	{name: 'Dagger', aoe: false, targetStat: 'ac', damageText: '(1d4 + 5) * {atks}', toHit: '1d20 + {dex} + {prof}'},
	{name: 'Fire ball', aoe: true, targetStat: 'dex', damageText: '8d6'},
	{name: 'Cone of Cold', aoe: true, targetStat: 'con', damageText: '8d8'},
];

export default function CharacterPage() {
	let characterName = 'Nillbert';
	let [character, set_character] = useState({});
	let {data:combat, mutate:set_combat} = useUserStore('combatState', characterName, {Aoe: 2});
	let {data:damageOptions, mutate:setDamageOptions} = useUserStore('characterDamageOptions', 'Nillbert', defaultDamageOptions);
	let [damageModalDialog, toggleDamageModal] = useModal(useDamageModalContent);
	let [damageEditModalDialog, toggleDamageEditModal] = useModal(useDamageEditModalContent, onSave => {
		let next= JSON.parse(JSON.stringify(damageOptions));
		let i = onSave.i;
		delete onSave.i;
		next[i] = onSave;
		setDamageOptions(next);
	});

	let addNewDamageOption = () => {
		setDamageOptions([...damageOptions, {name: `untitled #${damageOptions.length}`, aoe: false, targetStat: 'ac', damageText: '1d6'}]);
	};

	let damage_options_element = (
		<>
		<h3>Damage Options <button className='primary' onMouseDown={addNewDamageOption}>+</button></h3>
		<table>
			<thead>
				<tr>
					<th scope="col">Name</th>
					<th scope="col">Damage</th>
					<th scope="col">Targets</th>
					<th scope="col">Aoe</th>
					<th scope="col"></th>
				</tr>
			</thead>
			<tbody>
				{damageOptions.map((dmgOpt, i) => (
				<tr key={i}>
					<td>{dmgOpt.name}</td>
					<td>{dmgOpt.damageText}</td>
					<td>{dmgOpt.targetStat}</td>
					<td>{dmgOpt.aoe ? 'aoe' : 'single'}</td>
					<td><button onMouseDown={() => toggleDamageEditModal({...dmgOpt, character, i})}>edit</button>
					<button onMouseDown={() => toggleDamageModal({...dmgOpt, character})}>view</button></td>
				</tr>
				))}
			</tbody>
		</table>
		</>
	);
	let combat_element = (
		<>
		{JSON.stringify(combat)}
		</>
	);

	let tabs = Tabs({
		'Stats / Level': <CharacterStatsPerLevel SetCharacter={set_character}/>,
		'Damage Options': damage_options_element,
		'Combat': combat_element,
	});

	return (
	<>
		{tabs}
		{damageModalDialog}{damageEditModalDialog}
	</>
	);
}

