import '../styles/Spells.sass';
import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useQuery } from "@tanstack/react-query";
import FuzzySearch from 'fuzzy-search';
import ReactPaginate from 'react-paginate';

async function fetchSpells() {
	return await axios.get('/spell').then(x => x?.data).catch(e => {console.error(e); return []});
}

function filterSpells(spells, slot) {
	slot = parseInt(slot);
	return spells?.filter(spell => (slot === -1 || spell.level === slot)) ?? [];
}
function clamp(min, med, max) { return Math.max(min, Math.min(med,max)); }

export default function Spells() {
	const [spellSearch, setSpellSearch] = useState('');
	const [slotFilter, setSlotFilter] = useState('-1');
	const [selectedSpell, setSelectedSpell] = useState({class:[]});
	const [paging, setPaging] = useState({ perPage: 10, pageNumber: 1 });
	const dialogRef = useRef(null);
	const { data, isLoading, error } = useQuery({queryKey: ["allspells"], queryFn: fetchSpells, staleTime: 10 * 1000});

	if(isLoading) return (<div>Loading</div>);
	if(error) return (<div>Error: {error.message}</div>);

	let spells = filterSpells(data, slotFilter);

	if(spellSearch !== undefined && spellSearch !== '') {
		const splits = spellSearch.split('and').flatMap(x => x.split('&'));
		for(let s of splits) {
			s = s.trim();
			if(s === '')
				continue;
			const searcher = new FuzzySearch(spells, ['name', 'class', 'school'], {
				caseSensitive: false,
			});
			spells = searcher.search(s);
		}
	}
	const maxPageNumber = Math.ceil(Math.max(1, spells.length) / paging.perPage);
	spells = spells.slice(clamp(0, (paging.perPage * (paging.pageNumber - 1)), spells.length - 2),
		clamp(1, (paging.perPage * paging.pageNumber), spells.length - 1));

	function toggleDialog() {
		if(dialogRef.current === null || dialogRef.current === undefined)
			return;
		if(dialogRef.current.hasAttribute('open'))
			dialogRef.current.close();
		else
			dialogRef.current.showModal();
	}
	return (
	<>
		<dialog ref={dialogRef}>
			<a className="close" onMouseDown={toggleDialog}/>
			<h1 className='spellHeading'>{selectedSpell.name}</h1> 
			<div className='quickSection'>
				<div>{selectedSpell.components}</div>
				<div>{selectedSpell.castingTime} <span hidden={!selectedSpell.duration}>{selectedSpell.duration}</span></div>
				<div><span hidden={!selectedSpell.area}>15 ft cone</span> <span hidden={!selectedSpell.concentration}> - Concentration</span></div>
			</div>
			<div>
				<span>Level: {selectedSpell.level} {selectedSpell.school}</span>
				<br/>
				<br/>
				{selectedSpell.class.map((s, i) => <span key={i} className={'pill-' + s + ' small-pill'}>{s[0]}</span>)}
			</div>
			<div dangerouslySetInnerHTML={{ __html: selectedSpell.description }} />
		</dialog>

		<input type="search" spellCheck="false" placeholder="Search: wizard and fire" value={spellSearch}
		onChange={e => {setPaging({pageNumber: 1, perPage: paging.perPage}); setSpellSearch(e.target.value.toLowerCase())}}/>

		<span> Spell Level: </span>
		<select onChange={e => setSlotFilter(e.target.value)}>
			<option value={-1}>All</option>
			{[0,1,2,3,4,5,6,7,8,9].map(i => <option key={i} value={i}>{i}</option>)}
		</select>

		<table style={{width: '100%'}}>
			<thead>
				<tr>
					<th scope="col">Name</th>
					<th scope="col">Level</th>
					<th scope="col" className="hide-mobile">Concentration</th>
					<th scope="col" className="hide-mobile">Class</th>
					<th scope="col" className="hide-mobile">School</th>
				</tr>
			</thead>
			<tbody>
				{spells.map(spell => (
				<tr key={spell.id}>
					<td><a className="primary" onMouseDown={() => {setSelectedSpell(spell); toggleDialog();}}>{spell.name}</a></td>
					<td>{spell.level}</td>
					<td className="hide-mobile">{spell.concentration ? 'Concentration' : ''}</td>
					<td className="hide-mobile">
						{spell.class.map((s, i) => <span key={i} className={'pill-' + s + ' small-pill'}>{s[0]}</span>)}
					</td>
					<td className="hide-mobile">{spell.school}</td>
				</tr>
				))}
			</tbody>
		</table>
		<div className='center_contents'>
			<ReactPaginate
				breakLabel="..."
				nextLabel="next >"
				onPageChange={(e) => setPaging({...paging, pageNumber: e.selected + 1})}
				pageRangeDisplayed={5}
				pageCount={maxPageNumber}
				previousLabel="< previous"
				renderOnZeroPageCount={null}
			/>
		</div>

		<div>
			<span>Per Page:</span>
			<select onChange={e => setPaging({perPage: e.target.value, pageNumber: paging.pageNumber})}>
				{[10, 25, 50].map(i => <option key={i} value={i}>{i}</option>)}
			</select>
		</div>
	</>
	);
}
